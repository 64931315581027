const MediaPlayer = require('./MediaPlayer');
const MediaPlayerUI = require('./MediaPlayerUI');
const MediaBtn = require('./components/MediaBtn.js');
const MediaIcon = require('./components/Icon.js');

module.exports = {
  MediaPlayer,
  MediaPlayerUI,
  MediaBtn,
  MediaIcon,
};
