// IMPORANT: This date should be an Atom date string
// You can use a generator to create the timestamp, such as
// https://timestampgenerator.com/

// P.S. Make sure your time is 24 hour time, so 9pm PST is 22:00
//      During Daylight Saving Time (summer time), 9pm PDT is 21:00
// !!!!

const NEXT_SHOW = '2021-08-29T13:00:00-07:00';

export default NEXT_SHOW;
