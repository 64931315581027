const instagram = (username) => `https://instagram.com/${username}`;
const twitter = (username) => `https://twitter.com/${username}`;

export default [
  {
    screenName: 'Xx_AlexJacobs_xX',
    count: 18.5,
    link: twitter('spiffythedog'),
  },
  {
    screenName: 'aRiEL_gArDner69',
    count: 17.5,
    link: twitter('arielwav'),
  },
  { screenName: 'Robby.net_', count: 17, link: 'http://robbymassey.com' },
  { screenName: 'AUST1N__ST0CK', count: 7, link: instagram('mrironmonkey') },
  {
    screenName: 'xXellie_pritts88',
    count: 17.5,
    link: 'https://vimeo.com/elliepritts',
  },
  { screenName: '*danny_jelinek*', count: 4, link: instagram('listgenerator') },
  { screenName: 'DaveSeger_2000', count: 13, link: twitter('daveseger') },
  {
    screenName: 'J0sh_x_SasS0n',
    count: 12,
    link: instagram('giuseppe_sassollini'),
  },
  { screenName: 't3d_marsd3n', count: 14, link: 'https://www.tedmarsden.com/' },
  { screenName: 'SEAN0BRIEN_', count: 10, link: instagram('seanmobrien') },
  { screenName: 'JaS0N__gUdAsZzz', count: 5, link: instagram('jasongudasz') },
  { screenName: 'jake!!r0binson_', count: 14, link: instagram('jerobinson') },
  { screenName: '<3Ben_pluimer_xx', count: 11, link: instagram('pluimer') },
  {
    screenName: 'danceebee',
    count: 9,
    link: instagram('thedanceebee'),
  },
  { screenName: 'freddie.W0Ng***', count: 3, link: twitter('fwong') },
  { screenName: 'Je$$_lane', count: 14, link: 'https://www.notjesslane.com/' },
  { screenName: '_paul.isakson_85', count: 8, link: instagram('rudenudedude') },
  { screenName: 'kyl3_REITER', count: 3, link: instagram('kreiter_') },
  { screenName: 'x_eRn1_wAlkEr_x', count: 1, link: instagram('ernaisha') },
  {
    screenName: 'jordanmichaelblak3_',
    count: 3,
    link: instagram('jordanmichaelblake'),
  },
  { screenName: 'PATR1CK.mEraZ', count: 1, link: instagram('patmz_pics') },
  { screenName: '~!~darby.rose~!~', count: 9, link: instagram('darbleezy') },
  { screenName: '*hArr1son__atkins*', count: 11, link: instagram('hhharkrr') },
  { screenName: 'jEn*RuIz_', count: 1, link: instagram('jenniferruiz') },
  { screenName: 'xxCASEy_DONAHU3', count: 11, link: instagram('caseydonahue') },
  {
    screenName: '~Gr1my_gh0$t~',
    count: 8 + 2,
    link: instagram('thegrimyghost'),
  },
  { screenName: 'xxZAC.beCk3R', count: 1, link: instagram('azacbeckerfilm') },
  { screenName: 'ryan_perez_xx', count: 3, link: instagram('ryguyperez') },
  { screenName: 'B0BBYxxMCc0y_', count: 4, link: instagram('iambobbymccoy') },
  { screenName: '__₵hl03.Br3tt__', count: 11, link: instagram('clobrett') },
  {
    screenName: 'x*x_marisa.demarini_x*x',
    count: 11 + 2,
    link: instagram('meese.jpg'),
  },
  {
    screenName: 'THE_CLAIRE_LEVINSON_tm',
    count: 8,
    link: instagram('bearsrambling'),
  },
  {
    screenName: '~noLAN_FABricus~',
    count: 4,
    link: instagram('nolanfabricius'),
  },
  { screenName: '_corygarcia_', count: 1, link: instagram('_corygarcia_') },
  {
    screenName: 'paul bonnano (offline)',
    count: 6,
    link: instagram('paulbonanno'),
  },
  {
    screenName: 'XxX_moles.aaron_XxX',
    count: 3,
    link: instagram('aaronmoles'),
  },
  {
    screenName: 'cri$tina~i$abel~rivera',
    count: 3,
    link: instagram('cristinaisabelrivera'),
  },
  {
    screenName: 'xx_AdaM.WingarD_xx',
    count: 2,
    link: instagram('adamwingard'),
  },
  { screenName: 'KATe__frEund_', count: 1, link: instagram('katersfreund') },
  { screenName: '~*kati...skelton*~', count: 5, link: instagram('whereskati') },
  { screenName: '~R!CH3LL3_CH3N~', count: 2, link: instagram('yard__sard') },
  { screenName: 'jonahRAY!', count: 1, link: instagram('jonahray') },
  { screenName: 'br0die__r33d', count: 2, link: twitter('ayobrobro') },
  { screenName: '**starline*hodge**', count: 1, link: instagram('starline') },
  { screenName: 'dri_sommer', count: 1 },
  { screenName: 'x_X_milos_mitrovic_X_x', count: 2 },
  { screenName: 'm4tth3w.w3ll$', count: 2 },
  {
    screenName: 'zyX_daniel_johnson_Xyz',
    count: 2,
    link: 'https://danieljohnsonfilm.com',
  },
  { screenName: 'S K Y ♡ K I M', count: 1, link: instagram('skylovesjuice') },
  { screenName: `x♡lola_isabel♡x`, count: 1, link: instagram('tresbonne') },
  {
    screenName: `hazel rose`,
    count: 1,
  },
  {
    screenName: `nina reyes rosenberg`,
    count: 1,
  },
  {
    screenName: '_notjerryjerry',
    link: 'https://linktr.ee/_notjerryjerry',
    count: 1,
  },
  {
    screenName: `d3r3K_R31n1ng`,
    link: 'https://instagram.com/dreiningdesign',
    count: 1,
  },
].sort((a, b) => b.count - a.count);
