import React, { useState, useEffect, useRef } from 'react';
import useTimeout from 'use-timeout';
import styled from 'styled-components';
import Div100vh from 'react-div-100vh';
import { navigate } from 'gatsby';
import { parse, stringify } from 'query-string';
import {
  Anchor,
  AppBar,
  Bar,
  Button,
  Checkbox,
  Cutout,
  Divider,
  Fieldset,
  List,
  ListItem as OriginalListItem,
  NumberField,
  Select,
  Tab,
  TabBody,
  Tabs,
  Toolbar,
  Tooltip,
  WindowContent,
  WindowHeader,
} from 'react95';

import DraggableWindow from '../DraggableWindow.js';
import BrowserWindow from '../BrowserWindow.js';

import purpleBubbles from '../../images/bubbles/purple.png';
import pullmanWallpaper from '../../images/pullman-wallpaper.jpg';
import clockFace from '../../images/clockFace.png';
import popupRtiff from '../../images/popup/rtiff.gif';

import twitterIcon from '../../images/socials/twitter.png';
import instagramIcon from '../../images/socials/instagram.png';
import twitchIcon from '../../images/socials/twitch.png';
import spotifyIcon from '../../images/socials/spotify.png';
import outlookExpressIcon from '../../images/socials/outlook.png';
import bandcampIcon from '../../images/socials/bandcamp.png';
import fritostart from '../../images/fritostart.png';
import creditsIcon from '../../images/desktop/credits.png';
import trashIcon from '../../images/desktop/trash.png';
import billIcon from '../../images/desktop/bill.jpg';
import webpageIcon from '../../images/desktop/webpage.gif';
import ieIcon from '../../images/browser/ie.png';
import mediaPlayerIcon from '../../images/desktop/mediaplayer.png';
import aimIcon from '../../images/desktop/aim.png';
import myVideosIcon from '../../images/desktop/myvideos.png';
import explorerAacIcon from '../../images/explorer/aac.png';
import explorerAviIcon from '../../images/explorer/avi.png';
import explorerFlvIcon from '../../images/explorer/flv.png';
import explorerM4rIcon from '../../images/explorer/m4r.png';
import explorerMidiIcon from '../../images/explorer/midi.png';
import explorerMkvIcon from '../../images/explorer/mkv.png';
import explorerQuicktimeIcon from '../../images/explorer/quicktime.png';
import explorerMp3Icon from '../../images/explorer/mp3.png';
import explorerVideoIcon from '../../images/explorer/video.png';
import explorerWavIcon from '../../images/explorer/wav.png';
import errorIcon from '../../images/error.png';

import { PlayerContextProvider } from '../media/cassette/cassette-core.js';
import { MediaPlayerUI, MediaBtn } from '../media/index.js';

import ROSTER from '../../data/roster.js';
import VIDEOS from '../../data/videos.js';

const TinySpacer = () => <div style={{ width: 3 }} />;

const ListItem = styled(OriginalListItem)`
  justify-content: flex-start;
  align-items: center;

  img,
  span[role='img'] {
    width: 20px;
    font-size: 20px;
    margin-right: 0.5rem;
  }
`;

const SocialLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  padding: 0 3px;
  border: 2px solid transparent;

  &:hover {
    border-left-color: #df9be7;
    border-top-color: #df9be7;
    border-right-color: #010001;
    border-bottom-color: #010001;
  }

  &:focus {
    border-left-color: #010001;
    border-top-color: #010001;
    border-right-color: #df9be7;
    border-bottom-color: #df9be7;
  }

  @media only screen and (max-width: 360px) {
    padding: 0 1px;
  }
`;

const OpenOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const FritoStartButton = styled(Button)`
  img {
    height: 20px;
  }

  span {
    margin-left: 4px;

    @media only screen and (max-width: 410px) {
      display: none;
    }
  }
`;

const TaskbarClockCutout = styled(Cutout)`
  padding: 3px 15px;

  @media only screen and (max-width: 415px) {
    padding: 3px 10px;
  }

  @media only screen and (max-width: 360px) {
    padding: 3px 6px;
  }
`;

const Taskbar = ({
  setCreditsOpen,
  setMediaListOpen,
  setBrowserOpen,
  toggleDateTimeOpen,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <AppBar style={{ top: 'auto', bottom: '0', zIndex: 9999999 }}>
      {open && <OpenOverlay onClick={() => setOpen(false)} />}
      <Toolbar style={{ justifyContent: 'space-between' }}>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            paddingRight: '2px',
          }}
        >
          <FritoStartButton
            onClick={() => setOpen(!open)}
            active={open}
            style={{ fontWeight: 'bold' }}
          >
            <img src={fritostart} alt="" />
            <span>Start</span>
          </FritoStartButton>
          {open && (
            <List
              style={{
                position: 'absolute',
                left: '0',
                bottom: '100%',
                minWidth: 160,
              }}
              onClick={() => setOpen(false)}
            >
              <ListItem onClick={() => setMediaListOpen(true)}>
                <img
                  src={myVideosIcon}
                  alt="My Videos"
                  style={{ marginTop: '-2px' }}
                />
                My Videos
              </ListItem>
              <ListItem onClick={() => setCreditsOpen(true)}>
                <img src={creditsIcon} alt="Credits" />
                Credits
              </ListItem>
              <ListItem onClick={() => setBrowserOpen(true)}>
                <img src={ieIcon} alt="Internet Explorer" />
                Internet Explorer
              </ListItem>
              <Divider />
              <ListItem disabled>
                <span role="img" aria-label="🔙">
                  🔙
                </span>
                Logout
              </ListItem>
            </List>
          )}

          <Bar size={28} style={{ margin: 'auto 4px auto 7px' }} />

          <Tooltip
            text="Send us an E-mail"
            enterDelay={100}
            style={{
              top: '-4px',
              bottom: 'auto',
              transform: 'translate(-50%, -100%)',
            }}
          >
            <SocialLink
              href="mailto:speed@racertrash.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={outlookExpressIcon}
                height="20"
                alt="Outlook Express Icon"
              />
            </SocialLink>
          </Tooltip>
          <Tooltip
            text="Twitter"
            enterDelay={100}
            style={{
              top: '-4px',
              bottom: 'auto',
              transform: 'translate(-50%, -100%)',
            }}
          >
            <SocialLink
              href="https://twitter.com/racertrash"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitterIcon} height="20" alt="Twitter" />
            </SocialLink>
          </Tooltip>
          <Tooltip
            text="Instagram"
            enterDelay={100}
            style={{
              top: '-4px',
              bottom: 'auto',
              transform: 'translate(-50%, -100%)',
            }}
          >
            <SocialLink
              href="https://instagram.com/racertrash"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagramIcon} height="20" alt="Instagram" />
            </SocialLink>
          </Tooltip>
          <Tooltip
            text="Twitch"
            enterDelay={100}
            style={{
              top: '-4px',
              bottom: 'auto',
              transform: 'translate(-50%, -100%)',
            }}
          >
            <SocialLink
              href="https://twitch.tv/racerxtrash"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitchIcon} height="20" alt="Twitch.tv" />
            </SocialLink>
          </Tooltip>
          <Tooltip
            text="Spotify"
            enterDelay={100}
            style={{
              top: '-4px',
              bottom: 'auto',
              transform: 'translate(-50%, -100%)',
            }}
          >
            <SocialLink
              href="https://open.spotify.com/playlist/3eNoN8LQOKWw1B6cMGmBhO?si=YjTOgQ-DRbeFB1h701aSrg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={spotifyIcon}
                height="20"
                alt="Spotify"
                style={{ marginTop: '-1px' }}
              />
            </SocialLink>
          </Tooltip>
          <Tooltip
            text="Bandcamp"
            enterDelay={100}
            style={{
              top: '-4px',
              bottom: 'auto',
              transform: 'translate(-50%, -100%)',
            }}
          >
            <SocialLink
              href="https://racertrash.bandcamp.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={bandcampIcon}
                height="20"
                alt="Bandcamp"
                style={{ marginTop: '-1px' }}
              />
            </SocialLink>
          </Tooltip>

          <div style={{ marginLeft: 'auto' }}></div>

          <Tooltip
            text="Tuesday, April 20, 420"
            enterDelay={100}
            style={{
              top: '-4px',
              bottom: 'auto',
              left: 'auto',
              right: '-4px',
              transform: 'translate(0, -100%)',
            }}
          >
            <TaskbarClockCutout onClick={() => toggleDateTimeOpen()}>
              4:20 PM
            </TaskbarClockCutout>
          </Tooltip>
        </div>
      </Toolbar>
    </AppBar>
  );
};

const StyledDesktopIcon = styled.button`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  background: none;
  width: 70px;
  height: 70px;
  border: 0;
  margin: 4px 4px 20px;
  color: white;
  font: inherit;
  line-height: 1;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.8);

  span {
    background: #c862cd;
  }

  img {
    margin-bottom: 4px;
  }

  &:focus {
    span {
      outline: 1px dotted black;
      background: #0f0;
    }

    img {
      filter: grayscale(100%) sepia(100%) hue-rotate(-120deg) brightness(120%);
    }
  }

  &:active {
    span {
      background: #924496;
    }

    img {
      filter: grayscale(100%) sepia(100%) hue-rotate(-120deg) brightness(80%);
    }
  }

  &::-moz-focus-inner {
    border: 0;
  }
`;

const DesktopIcon = ({ src, text, onClick = () => {} }) => {
  const [active, setActive] = useState(false);

  return (
    <StyledDesktopIcon
      onClick={(event) => {
        if (active) {
          onClick();
          setActive(false);
        } else {
          event.target.closest('button').focus();
          setActive(true);
        }
      }}
      onBlur={() => {
        if (
          navigator.vendor === 'Apple Computer, Inc.' ||
          navigator.userAgent.includes('Firefox')
        ) {
          return;
        }
        setActive(false);
      }}
    >
      <img src={src} alt={text} width="32" height="32" />
      <span>{text}</span>
    </StyledDesktopIcon>
  );
};

const StyledExplorerIcon = styled.button`
  display: inline-flex;
  flex-direction: row;
  background: none;
  width: 220px;
  border: 0;
  margin: 2px;
  color: #000;
  font: inherit;
  line-height: 1;
  text-align: left;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  img {
    margin-right: 4px;
  }

  &:focus,
  &:active {
    color: white;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.8);
  }

  &:focus {
    span {
      outline: 1px dotted black;
      background: #0f0;
    }

    img {
      filter: grayscale(100%) sepia(100%) hue-rotate(-120deg) brightness(120%);
    }
  }

  &:active {
    span {
      background: #924496;
    }

    img {
      filter: grayscale(100%) sepia(100%) hue-rotate(-120deg) brightness(80%);
    }
  }

  &::-moz-focus-inner {
    border: 0;
  }
`;

const ExplorerIcon = ({ src, text, onClick = () => {} }) => {
  const [active, setActive] = useState(false);

  return (
    <StyledExplorerIcon
      onClick={(event) => {
        if (active) {
          onClick();
          setActive(false);
        } else {
          event.target.closest('button').focus();
          setActive(true);
        }
      }}
      onBlur={() => {
        if (
          navigator.vendor === 'Apple Computer, Inc.' ||
          navigator.userAgent.includes('Firefox')
        ) {
          return;
        }
        setActive(false);
      }}
    >
      <img src={src} alt={text} width="16" height="16" />
      <span>{text}</span>
    </StyledExplorerIcon>
  );
};

const BuddyButton = styled.button`
  border: 0;
  background: none;
  text-align: left;

  &:hover {
    color: inherit;
  }

  &:focus {
    outline: 1px dotted black;
  }

  &::-moz-focus-inner {
    border: 0;
  }
`;

const RacerTrashRoster = () => {
  const [state, setState] = useState({
    buddyExpanded: true,
    buddyActive: false,

    webmasterExpanded: true,
    webmasterActive: false,
  });

  const {
    buddyExpanded,
    buddyActive,
    webmasterExpanded,
    webmasterActive,
  } = state;

  return (
    <div style={{ fontSize: '14px' }}>
      <BuddyButton
        style={{ fontWeight: 'bold' }}
        onClick={(event) => {
          if (buddyActive) {
            setState({
              ...state,
              buddyExpanded: !buddyExpanded,
              buddyActive: false,
            });
          } else {
            setState({ ...state, buddyActive: true, webmasterActive: false });
            event.target.focus();
          }
        }}
        onBlur={(event) => {
          if (
            navigator.vendor === 'Apple Computer, Inc.' ||
            navigator.userAgent.includes('Firefox')
          ) {
            return;
          }

          setState({ ...state, buddyActive: false });
        }}
      >
        {buddyExpanded ? '▼' : '►'} Buddies ({ROSTER.length} / 420)
      </BuddyButton>
      {buddyExpanded && (
        <div style={{ marginLeft: '1.5em' }}>
          {ROSTER.map(({ screenName, link }) => (
            <div key={screenName}>
              <BuddyButton
                as="a"
                href={link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {screenName}
              </BuddyButton>
            </div>
          ))}
        </div>
      )}

      <BuddyButton
        style={{ fontWeight: 'bold' }}
        onClick={(event) => {
          if (webmasterActive) {
            setState({
              ...state,
              webmasterExpanded: !webmasterExpanded,
              webmasterActive: false,
            });
          } else {
            setState({ ...state, buddyActive: false, webmasterActive: true });
            event.target.focus();
          }
        }}
        onBlur={(event) => {
          if (
            navigator.vendor === 'Apple Computer, Inc.' ||
            navigator.userAgent.includes('Firefox')
          ) {
            return;
          }

          setState({ ...state, webmasterActive: false });
        }}
      >
        {webmasterExpanded ? '▼' : '►'} Webmaster (1 / 1)
      </BuddyButton>
      {webmasterExpanded && (
        <div style={{ marginLeft: '1.5em' }}>
          {[{ screenName: 'xyz_jdhrtl_zyx', link: 'https://jdhartley.me' }].map(
            ({ screenName, link }) => (
              <div key={screenName}>
                <BuddyButton
                  as="a"
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {screenName}
                </BuddyButton>
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

const CenteredDraggableWindow = styled(DraggableWindow)`
  width: 400px;
  max-width: 95vw;
  position: absolute;
  top: calc(50vh - 128px);
  left: calc(50vw - 200px);

  @media only screen and (max-width: 400px) {
    left: 2.5vw;
  }
`;

const CreditsContent = styled.div`
  padding: 8px;
  height: 300;
  max-height: 40vh;
  overflow: auto;

  h3 {
    font-weight: bold;
  }

  h3:not(:first-child) {
    margin-top: 1em;
  }

  a {
    text-decoration: underline;
  }
`;

const CreditsWindow = ({ onClose }) => {
  return (
    <CenteredDraggableWindow
      style={{
        width: 350,
        top: '66px',
      }}
    >
      <WindowHeader className="handle" style={{ lineHeight: '30px' }}>
        <Toolbar
          style={{
            padding: 0,
          }}
        >
          <span style={{ flexGrow: 1 }}>Credits.doc</span>
          <MediaBtn
            title="Close"
            icon="x"
            style={{ width: '24px', height: '24px' }}
            onClick={onClose}
            onTouchEnd={onClose}
          />
        </Toolbar>
      </WindowHeader>
      <WindowContent>
        <Cutout
          style={{
            background: 'white',
          }}
        >
          <CreditsContent>
            <h3>I'm speed coder and I type real fast</h3>
            <p>
              * Website by{' '}
              <a
                href="https://jdhartley.me/"
                target="_blank"
                rel="noopener noreferrer"
              >
                JD Hartley
              </a>
            </p>

            <h3>Powered by Open Source</h3>
            <p>* React + GatsbyJS</p>
            <p>* arturbien/React95</p>
            <p>* benwiley4000/cassette</p>

            <h3>Assets &amp; Creative Direction</h3>
            <p>
              *{' '}
              <a
                href="https://instagram.com/elliepritts"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ellie Pritts
              </a>
            </p>

            <h3>Additional Artwork</h3>
            <p>
              *{' '}
              <a
                href="https://twitter.com/mbrhmr"
                target="_blank"
                rel="noopener noreferrer"
              >
                Matt Brohammer
              </a>
            </p>

            <h3>Special Thanks</h3>
            <p>
              *{' '}
              <a
                href="https://www.expensive.toys/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Artur Bień
              </a>
            </p>
            <p>
              *{' '}
              <a
                href="https://codepen.io/inegoita"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ion Emil Negoita
              </a>
            </p>
            <p>
              *{' '}
              <a
                href="https://codepen.io/FelixLuciano/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Felix Luciano
              </a>
            </p>
            <p>
              *{' '}
              <a
                href="https://codepen.io/virgilb"
                target="_blank"
                rel="noopener noreferrer"
              >
                Virgil B.
              </a>
            </p>
            <p>
              *{' '}
              <a
                href="https://www.fiverr.com/ilmyart"
                target="_blank"
                rel="noopener noreferrer"
              >
                ilmyart
              </a>
            </p>
            <p>* Brian Pillman Jr.</p>

            <h3>Hosted by</h3>
            <p>* Netlify</p>
            <p>* DigitalOcean Spaces</p>
          </CreditsContent>
        </Cutout>
      </WindowContent>
    </CenteredDraggableWindow>
  );
};

const MonthCutout = styled(Cutout)`
  div {
    display: flex;
    gap: 5px;
    padding: 4px 5px 4px 2px;
  }

  div:first-child {
    background: #a130a9;
    color: #df9be7;
  }

  span {
    flex: 1 0;
    text-align: right;
    padding: 2px 4px;
  }
`;

const DateTimeWindow = ({ onClose }) => {
  const timeRef = useRef({});
  useEffect(() => {
    if (timeRef.current) {
      timeRef.current.type = 'text';
      timeRef.current.value = '4:20:69 PM';
      timeRef.current.style.textAlign = 'center';
    }
    return () => {};
  }, [timeRef]);

  return (
    <CenteredDraggableWindow
      style={{
        width: 640,
        maxWidth: 'none',
        top: '66px',
      }}
    >
      <WindowHeader className="handle" style={{ lineHeight: '30px' }}>
        <Toolbar
          style={{
            padding: 0,
          }}
        >
          <span style={{ flexGrow: 1 }}>Date/Time Properties</span>
          <MediaBtn
            title="Close"
            icon="x"
            style={{ width: '24px', height: '24px' }}
            onClick={onClose}
            onTouchEnd={onClose}
          />
        </Toolbar>
      </WindowHeader>
      <WindowContent>
        <Tabs value={0}>
          <Tab value={0}>Date &amp; Time</Tab>
        </Tabs>
        <TabBody>
          <div style={{ display: 'flex', gap: '20px', marginBottom: 20 }}>
            <div style={{ flex: '1' }}>
              <Fieldset
                label={
                  <>
                    <u>D</u>ate
                  </>
                }
              >
                <div style={{ display: 'flex', gap: '10px', marginBottom: 20 }}>
                  <Select
                    defaultValue={4}
                    options={[{ value: 4, label: 'April' }]}
                    style={{ flex: '1', width: '50%' }}
                  />
                  <NumberField
                    value={420}
                    style={{ flex: '1', width: '50%' }}
                  />
                </div>

                <MonthCutout>
                  <div>
                    <span>S</span>
                    <span>M</span>
                    <span>T</span>
                    <span>W</span>
                    <span>T</span>
                    <span>F</span>
                    <span>S</span>
                  </div>
                  <div>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span>1</span>
                    <span>2</span>
                    <span>3</span>
                  </div>
                  <div>
                    <span>4</span>
                    <span>5</span>
                    <span>6</span>
                    <span>7</span>
                    <span>8</span>
                    <span>9</span>
                    <span>10</span>
                  </div>
                  <div>
                    <span>11</span>
                    <span>12</span>
                    <span>13</span>
                    <span>14</span>
                    <span>15</span>
                    <span>16</span>
                    <span>17</span>
                  </div>
                  <div>
                    <span>18</span>
                    <span>19</span>
                    <span style={{ background: '#0f0' }}>20</span>
                    <span>21</span>
                    <span>22</span>
                    <span>23</span>
                    <span>24</span>
                  </div>
                  <div>
                    <span>25</span>
                    <span>26</span>
                    <span>27</span>
                    <span>28</span>
                    <span>29</span>
                    <span>30</span>
                    <span></span>
                  </div>
                </MonthCutout>
              </Fieldset>
            </div>
            <div style={{ flex: '1' }}>
              <Fieldset
                label={
                  <>
                    <u>T</u>ime
                  </>
                }
              >
                <div
                  style={{
                    backgroundImage: `url(${clockFace})`,
                    backgroundSize: 'cover',
                    paddingTop: '100%',
                    marginBottom: 8,
                  }}
                />
                <div style={{ width: '145px', margin: 'auto' }}>
                  <NumberField value="4:20:69 PM" ref={timeRef} />
                </div>
              </Fieldset>
            </div>
          </div>

          <Fieldset
            label={
              <>
                Time&nbsp;<u>z</u>one
              </>
            }
          >
            <Select
              defaultValue={420}
              options={[
                { value: 420, label: '(GMT -04:20) High Times (US & Canada)' },
              ]}
              width="100%"
            />

            <Checkbox
              style={{ marginTop: 20, marginBottom: -10 }}
              checked={true}
              value={true}
              label="Automatically adjust clock for daylight saving changes"
              name="autoDaylightSaving"
            />
          </Fieldset>
        </TabBody>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '1em',
            marginTop: 10,
          }}
        >
          <Button style={{ width: 80 }} onClick={() => onClose()}>
            OK
          </Button>
          <Button style={{ width: 80 }} onClick={() => onClose()}>
            Cancel
          </Button>
          <Button style={{ width: 80 }} disabled>
            <u>A</u>pply
          </Button>
        </div>
      </WindowContent>
    </CenteredDraggableWindow>
  );
};

const MediaListContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 8px;
  height: 300;
  max-height: 40vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  scroll-snap-type: x mandatory;

  > * {
    scroll-snap-align: start;
  }

  h3 {
    font-weight: bold;
  }

  h3:not(:first-child) {
    margin-top: 1em;
  }

  a {
    text-decoration: underline;
  }
`;

const MediaListWindow = ({ onClose, videos, onPlayVideo }) => {
  const sortedVideos = [...videos].sort((a, b) => (a.title > b.title ? 1 : -1));
  return (
    <DraggableWindow
      style={{
        maxWidth: '92vw',
        position: 'absolute',
        bottom: '20px',
        transform: 'translateY(-100%)',
        left: '6vw',
        width: 550,
      }}
    >
      <WindowHeader className="handle" style={{ lineHeight: '30px' }}>
        <Toolbar
          style={{
            padding: 0,
          }}
        >
          <img
            src={myVideosIcon}
            alt="Buddy List"
            style={{ width: '16px', height: '16px', marginTop: '-2px' }}
          />
          <TinySpacer />
          <span style={{ flexGrow: 1 }}>My Videos</span>
          <MediaBtn
            title="Close"
            icon="x"
            style={{ width: '24px', height: '24px' }}
            onClick={onClose}
            onTouchEnd={onClose}
          />
        </Toolbar>
      </WindowHeader>
      <WindowContent>
        <Cutout
          style={{
            background: 'white',
          }}
        >
          <MediaListContent>
            {sortedVideos.map((video) => {
              const icon = video.title.includes('pullman')
                ? billIcon
                : ((ext) => {
                    switch (ext) {
                      case 'aac':
                        return explorerAacIcon;
                      case 'avi':
                        return explorerAviIcon;
                      case 'flv':
                        return explorerFlvIcon;
                      case 'm4r':
                        return explorerM4rIcon;
                      case 'midi':
                        return explorerMidiIcon;
                      case 'mkv':
                        return explorerMkvIcon;
                      case 'mov':
                        return explorerQuicktimeIcon;
                      case 'mp3':
                        return explorerMp3Icon;
                      case 'wav':
                        return explorerWavIcon;
                      default:
                        return explorerVideoIcon;
                    }
                  })(video.title.split('.').pop().toLowerCase());

              return (
                <ExplorerIcon
                  key={video.title}
                  src={icon}
                  text={video.title}
                  onClick={() => {
                    onPlayVideo(video.title);
                  }}
                ></ExplorerIcon>
              );
            })}
          </MediaListContent>
        </Cutout>
      </WindowContent>
    </DraggableWindow>
  );
};

const GameAlert = ({ onClose, fromAbout }) => {
  return (
    <CenteredDraggableWindow>
      <WindowHeader className="handle" style={{ lineHeight: '30px' }}>
        <Toolbar
          style={{
            padding: 0,
          }}
        >
          <span style={{ flexGrow: 1 }}>
            {fromAbout
              ? 'Restart Complete'
              : 'Woke Cat.exe - Application Error'}
          </span>
          <MediaBtn
            title="Close"
            icon="x"
            style={{ width: '24px', height: '24px' }}
            onClick={onClose}
            onTouchEnd={onClose}
          />
        </Toolbar>
      </WindowHeader>
      <WindowContent>
        <Cutout
          style={{
            background: 'white',
          }}
        >
          <div
            style={{
              display: 'flex',
              padding: '8px',
              overflow: 'auto',
            }}
          >
            <img src={errorIcon} width={48} height={48} alt="Alert: Error" />
            <div style={{ marginLeft: '20px' }}>
              {fromAbout ? (
                <div>Your computer was restarted due to a problem.</div>
              ) : (
                <div>Woke Cat.exe has stopped working</div>
              )}
              <Button onClick={onClose}>OK</Button>
            </div>
          </div>
        </Cutout>
      </WindowContent>
    </CenteredDraggableWindow>
  );
};

const PopupAdWindow = styled(DraggableWindow)`
  max-width: 92vw;
  position: absolute;
  top: 8px;
  right: 4vw;
  width: 550px;

  @media only screen and (min-width: 760px) {
    top: calc(4vw - 26px);
    right: calc(4vw - 26px);
  }

  @media only screen and (max-width: 960px) {
    &:nth-child(n + 2) {
      display: none;
    }
  }

  @media only screen and (max-height: 500px) {
    width: 420px;
  }

  &:nth-child(2),
  &:nth-child(5) {
    margin: 24px -24px 0 0;
  }
  &:nth-child(3),
  &:nth-child(6) {
    margin: 48px -48px 0 0;
  }
`;
const PopupAd = ({ onClose }) => {
  return (
    <PopupAdWindow>
      <WindowHeader className="handle" style={{ lineHeight: '30px' }}>
        <Toolbar
          style={{
            padding: 0,
          }}
        >
          <img
            src={webpageIcon}
            alt="Buddy List"
            style={{ width: '16px', height: '16px', marginTop: '-2px' }}
          />
          <TinySpacer />
          <span style={{ flexGrow: 1, minWidth: 0, overflow: 'hidden' }}>
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              congratulations you won! - Click Here! - Microsoft Internet
              Explorer
            </div>
          </span>
          <TinySpacer />
          <MediaBtn
            title="Close"
            icon="x"
            style={{ width: '24px', height: '24px', flexShrink: 0 }}
            onClick={onClose}
            onTouchEnd={onClose}
          />
        </Toolbar>
      </WindowHeader>
      <WindowContent>
        <Cutout
          style={{
            background: 'white',
          }}
        >
          <a
            href="https://racertrash.com/rtiff"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={popupRtiff}
              alt="Popup ad saying: Congratulations! You are the 69,420 visitor. Get Tickets for Racer Trash LIVE in Toronto!"
              width="1469"
              height="899"
              style={{
                display: 'block',
                width: '100%',
                height: 'auto',
              }}
            />
          </a>
        </Cutout>
      </WindowContent>
    </PopupAdWindow>
  );
};

const BuddyList = ({ onClose }) => {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (e, value) => setActiveTab(value);

  return (
    <DraggableWindow
      style={{
        width: 350,
        maxWidth: '95vw',
        position: 'absolute',
        bottom: '20px',
        transform: 'translateY(-100%)',
        right: '3vw',
      }}
    >
      <WindowHeader className="handle" style={{ lineHeight: '30px' }}>
        <Toolbar
          style={{
            padding: 0,
          }}
        >
          <img
            src={aimIcon}
            alt="Buddy List"
            style={{ width: '16px', height: '16px', marginTop: '-2px' }}
          />
          <TinySpacer />
          <span style={{ flexGrow: 1 }}>Buddy List.exe</span>
          <MediaBtn
            title="Close"
            icon="x"
            style={{ width: '24px', height: '24px' }}
            onClick={onClose}
            onTouchEnd={onClose}
          />
        </Toolbar>
      </WindowHeader>
      <WindowContent>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab value={0}>Online</Tab>
          <Tab disabled style={{ color: 'rgba(1, 0, 1, .3)' }} value={1}>
            List Setup
          </Tab>
        </Tabs>
        <TabBody>
          <Cutout
            style={{
              background: 'white',
            }}
          >
            <div
              style={{
                padding: '8px',
                height: 300,
                maxHeight: '40vh',
                overflow: 'auto',
              }}
            >
              {activeTab === 0 && <RacerTrashRoster />}
              {activeTab === 1 && (
                <>
                  <p style={{ margin: '0 0 1em' }}>
                    We are looking for creatives to join the RACER TRASH crew.
                  </p>
                  <p>
                    We'd love to hear from you at{' '}
                    <Anchor href="mailto:speed@racertrash.com">
                      speed@racertrash.com
                    </Anchor>
                    .
                  </p>
                </>
              )}
            </div>
          </Cutout>
        </TabBody>
      </WindowContent>
    </DraggableWindow>
  );
};

const DesktopEnvironmentWrapper = styled(Div100vh)`
  background: url(${purpleBubbles});
  font-size: 14px;
  user-select: none;
  overflow: hidden;

  ${(p) =>
    p.sc.pullman &&
    `
    background: url(${pullmanWallpaper}) center no-repeat white;
    background-size: contain;
  `}
`;

const StyledMediaPlayer = styled(MediaPlayerUI)`
  position: absolute;
  top: 5vh;
  left: 2vw;
  width: 640px;
  max-width: 96vw;

  @media only screen and (min-width: 760px) {
    top: 5vw;
    left: calc(70px + 5vw);
  }

  @media only screen and (max-height: 500px) {
    width: 280px;
  }
`;

const DesktopEnvironment = ({ location }) => {
  const [state, setState] = useState({
    buddyListOpen: true,
    mediaPlayerOpen: true,
    gameAlertOpen: true,
    creditsOpen: false,
    mediaListOpen: false,
    browserOpen: false,
    dateTimeOpen: false,
    popUpAds: [],
  });

  const {
    buddyListOpen,
    mediaPlayerOpen,
    gameAlertOpen,
    creditsOpen,
    mediaListOpen,
    browserOpen,
    dateTimeOpen,
    popUpAds,
  } = state;
  const setBuddyListOpen = (buddyListOpen) => {
    setState({ ...state, buddyListOpen });
  };
  const setMediaPlayerOpen = (mediaPlayerOpen) => {
    setState({ ...state, mediaPlayerOpen });
  };
  const setGameAlertOpen = (gameAlertOpen) => {
    setState({ ...state, gameAlertOpen });
  };
  const setCreditsOpen = (creditsOpen) => {
    setState({ ...state, creditsOpen });
  };
  const setMediaListOpen = (mediaListOpen) => {
    setState({ ...state, mediaListOpen });
  };
  const setBrowserOpen = (browserOpen) => {
    setState({ ...state, browserOpen });
  };
  const setDateTimeOpen = (dateTimeOpen) => {
    setState({ ...state, dateTimeOpen });
  };
  const popUpAdOpen = () => {
    //  setState({ ...state, popUpAds: [...popUpAds, +new Date()] });
  };
  const popUpAdClose = (key) => {
    setState({ ...state, popUpAds: popUpAds.filter((popup) => popup !== key) });
  };
  const toggleDateTimeOpen = () => setDateTimeOpen(!dateTimeOpen);

  const fromAbout = location && location.state && location.state.fromAbout;
  const queryParams = parse(location.search);
  const hashParams = parse(location.hash);
  const startingVideo =
    location &&
    ((location.state && location.state.startingVideo) ||
      queryParams.video ||
      hashParams.video);

  const REORDERED_VIDEOS = JSON.parse(JSON.stringify(VIDEOS));
  if (startingVideo) {
    const index = REORDERED_VIDEOS.findIndex(
      (video) => video.title === startingVideo
    );
    if (index) {
      const oldStart = REORDERED_VIDEOS.splice(0, index);
      REORDERED_VIDEOS.push(...oldStart);
    }
  }

  const onActiveTrackUpdate = ({ previousTrack, track }) => {
    if (track?.title !== previousTrack?.title) {
      navigate(
        '/work/#' + stringify({ video: track.title }).replace(/%20/g, '+'),
        {
          replace: true,
        }
      );
    }
  };

  // Open a popup basically right away, and then start opening more for a few minutes

  useTimeout(popUpAdOpen, 1500);
  useTimeout(popUpAdOpen, 1500 + 6 * 1000);
  useTimeout(popUpAdOpen, 1500 + 15 * 1000);
  useTimeout(popUpAdOpen, 1500 + 45 * 1000);
  useTimeout(popUpAdOpen, 1500 + 90 * 1000);
  useTimeout(popUpAdOpen, 1500 + 120 * 1000);
  useTimeout(popUpAdOpen, 1500 + 210 * 1000);

  return (
    <PlayerContextProvider
      playlist={REORDERED_VIDEOS}
      onActiveTrackUpdate={onActiveTrackUpdate}
    >
      {({ onSelectTrackIndex }) => {
        const playVideo = (title) => {
          setMediaPlayerOpen(true);
          onSelectTrackIndex(
            REORDERED_VIDEOS.findIndex((video) => video.title === title)
          );
          const video = document.querySelector('video');
          if (video) {
            video.currentTime = 0;
            video.dispatchEvent(new Event('durationchange'));
          }
        };

        return (
          <DesktopEnvironmentWrapper
            sc={{
              pullman: String(startingVideo).match(/big.year.for.pullman.mp4/),
            }}
            onMouseMove={(event) => event.preventDefault()}
          >
            <div
              id="desktop"
              style={{
                height: 'calc(100% - 47px)',
                overflow: 'hidden',
                position: 'relative',
                zIndex: 1,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'wrap',
                  height: '100%',
                  placeContent: 'start',
                }}
              >
                <DesktopIcon
                  src={trashIcon}
                  text="Racer Trash"
                  onClick={() => {
                    navigate('/about/');
                  }}
                />
                <DesktopIcon
                  src={fritostart}
                  text="Woke Cat"
                  onClick={() => {
                    navigate('/');
                  }}
                />
                <DesktopIcon
                  src={myVideosIcon}
                  text="My Videos"
                  onClick={() => {
                    setMediaListOpen(true);
                  }}
                />
                <DesktopIcon
                  src={billIcon}
                  text="Bill Pullman"
                  onClick={() => playVideo('big year for pullman.mp4')}
                />
                <DesktopIcon
                  src={mediaPlayerIcon}
                  text="Media Player"
                  onClick={() => {
                    setState({ ...state, mediaPlayerOpen: true });
                  }}
                />
                <DesktopIcon
                  src={aimIcon}
                  text="Buddy List"
                  onClick={() => {
                    setBuddyListOpen(true);
                  }}
                />
                <DesktopIcon
                  src={webpageIcon}
                  text="Racer Trash Movies"
                  onClick={() => setBrowserOpen(true)}
                />
              </div>

              <div>
                {popUpAds.map((key) => (
                  <PopupAd key={key} onClose={() => popUpAdClose(key)} />
                ))}
              </div>

              {buddyListOpen && (
                <BuddyList onClose={() => setBuddyListOpen(false)} />
              )}
              {mediaPlayerOpen && (
                <StyledMediaPlayer
                  className="player"
                  showVideo
                  autoplay
                  onClose={() => setMediaPlayerOpen(false)}
                />
              )}
              {gameAlertOpen && (
                <GameAlert
                  fromAbout={fromAbout}
                  onClose={() => {
                    const video = document.querySelector('video');
                    if (video && video.paused) {
                      video.play();
                    }
                    setGameAlertOpen(false);
                  }}
                />
              )}
              {creditsOpen && (
                <CreditsWindow onClose={() => setCreditsOpen(false)} />
              )}

              {browserOpen && (
                <BrowserWindow onClose={() => setBrowserOpen(false)} />
              )}

              {dateTimeOpen && (
                <DateTimeWindow onClose={() => setDateTimeOpen(false)} />
              )}

              {mediaListOpen && (
                <MediaListWindow
                  onClose={() => setMediaListOpen(false)}
                  videos={REORDERED_VIDEOS}
                  onPlayVideo={playVideo}
                />
              )}
            </div>
            <Taskbar
              {...{
                setBrowserOpen,
                setCreditsOpen,
                setMediaListOpen,
                toggleDateTimeOpen,
              }}
            />
          </DesktopEnvironmentWrapper>
        );
      }}
    </PlayerContextProvider>
  );
};

export default DesktopEnvironment;
