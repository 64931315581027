const React = require('react');
const theSixtiesUSA = require('react95/dist/themes/theSixtiesUSA');

const style = {
  height: 0,
  borderBottom: `1px solid ${theSixtiesUSA.borderLightest}`,
  margin: 0,
};

const SimpleDivider = () => {
  return <hr style={style} />;
};

module.exports = SimpleDivider;
