const React = require('react');
const styled = require('styled-components').default;
const PropTypes = require('prop-types');
const {
  playerContextFilter,
  PlayerPropTypes,
} = require('../cassette/cassette-core');
const { VideoDisplay } = require('../cassette/cassette-components');
const {
  WindowHeader,
  WindowContent,
  Toolbar,
  Button,
  Cutout,
  Divider,
} = require('react95');
const theSixtiesUSA = require('react95/dist/themes/theSixtiesUSA');
const { ThemeProvider } = require('styled-components');
const ResizeObserver = require('resize-observer-polyfill').default;

const DraggableWindow = require('../../DraggableWindow.js').default;

const ProgressBar = require('./ProgressBar');
const MediaBtn = require('./MediaBtn');
const SeekButton = require('./SeekButton');
const Icon = require('./Icon');
const SimpleDivider = require('./SimpleDivider');
const VerticalDivider = require('./VerticalDivider');
const convertToTime = require('../utils/convertToTime');

const FlatButton = styled(Button)`
  &::before {
    border-width: 0;
    box-shadow: none;
  }
`;

const headerToolbarStyle = {
  padding: 0,
};
const windowContentStyle = {
  padding: 0,
  marginRight: 2,
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
};
const scrollButtonContainerStyle = {
  marginTop: 5,
  marginRight: '0.3rem',
  marginLeft: '0.3rem',
  display: 'flex',
};
const scrollButtonStyle = {
  width: 20,
  height: 24,
  fontSize: '2em',
};

const scrollInterval = 1 / 30;

const Spacer = () => <div style={{ width: 8 }} />;
const TinySpacer = () => <div style={{ width: 3 }} />;

const titleBarButtonStyle = { width: '24px', height: '24px' };

class MediaPlayerView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      width: Infinity,
    };
    this.randomId = ('id' + Math.random()).replace(/\./g, '-');
    this.handleScrollForward = () => {
      this.props.onSeekComplete(this.props.currentTime + scrollInterval);
    };
    this.handleScrollBackward = () => {
      this.props.onSeekComplete(this.props.currentTime - scrollInterval);
    };
  }

  componentDidMount() {
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => {
        const elem = document.getElementById(this.randomId);
        if (!elem) {
          return;
        }
        this.resizeObserver = new ResizeObserver((entries) => {
          for (const entry of entries) {
            this.setState({
              width: entry.contentRect.width,
            });
          }
        });
        this.resizeObserver.observe(elem);
      });
    });
  }

  componentWillUnmount() {
    try {
      this.resizeObserver.disconnect();
    } catch {
      //
    }
  }

  render() {
    const {
      getDisplayText,
      showVideo,
      className,
      style,
      fullscreen,
      playlist,
      activeTrackIndex,
      paused,
      currentTime,
      onTogglePause,
      onBackSkip,
      onForwardSkip,
      onClose,
    } = this.props;
    const { width } = this.state;
    return (
      <ThemeProvider theme={theSixtiesUSA}>
        <div id={this.randomId}>
          <DraggableWindow
            style={{
              ...style,
              ...(fullscreen
                ? {
                    margin: 0,
                    width: '100%',
                    maxWidth: 'initial',
                    height: '100%',
                  }
                : {}),
            }}
            className={className}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: fullscreen ? '100%' : undefined,
              }}
            >
              <WindowHeader className="handle" style={{ lineHeight: '30px' }}>
                <Toolbar style={headerToolbarStyle}>
                  <TinySpacer />
                  <Icon name={showVideo ? 'video' : 'audio'} />
                  <TinySpacer />
                  <span
                    style={{
                      flexGrow: 1,
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {getDisplayText(playlist[activeTrackIndex])}
                    &nbsp; ({paused ? 'paused' : 'playing'})
                  </span>
                  <MediaBtn
                    title="Close"
                    icon="x"
                    style={titleBarButtonStyle}
                    onClick={onClose}
                    onTouchEnd={onClose}
                  />
                </Toolbar>
              </WindowHeader>
              <Toolbar style={{ ...headerToolbarStyle, position: 'relative' }}>
                {['File', 'Edit', 'Device', 'Scale', 'Help'].map(
                  (menuHeader) => (
                    <FlatButton
                      key={menuHeader}
                      style={{ fontSize: 13, height: '1.6em' }}
                      size="sm"
                      flat
                      disabled
                    >
                      <span style={{ textDecoration: 'underline' }}>
                        {menuHeader[0]}
                      </span>
                      {menuHeader.slice(1)}
                    </FlatButton>
                  )
                )}
              </Toolbar>
              <WindowContent style={windowContentStyle}>
                {showVideo && <VideoDisplay style={{ flexGrow: 1 }} />}
                <SimpleDivider />
                <div style={{ display: 'flex' }}>
                  <ProgressBar />
                  <div style={scrollButtonContainerStyle}>
                    <MediaBtn
                      title="Scroll Backward"
                      icon="backscroll"
                      style={scrollButtonStyle}
                      onClick={this.handleScrollBackward}
                    />
                    <MediaBtn
                      title="Scroll Forward"
                      icon="forwardscroll"
                      style={scrollButtonStyle}
                      onClick={this.handleScrollForward}
                    />
                  </div>
                </div>
                <Divider />
                <Toolbar>
                  <MediaBtn
                    title={paused ? 'Play' : 'Pause'}
                    icon={paused ? 'play' : 'pause'}
                    onClick={onTogglePause}
                  />
                  <MediaBtn title="Stop" icon="stop" disabled />
                  <MediaBtn title="Eject" icon="eject" disabled />
                  {width >= 260 && (
                    <React.Fragment>
                      <Spacer />
                      <MediaBtn
                        title="Previous"
                        icon="backskip"
                        onClick={onBackSkip}
                      />
                      <SeekButton type="rewind" />
                      <SeekButton type="fastforward" />
                      <MediaBtn
                        title="Next"
                        icon="forwardskip"
                        onClick={onForwardSkip}
                      />
                    </React.Fragment>
                  )}
                  {width >= 310 && (
                    <React.Fragment>
                      <Spacer />
                      <MediaBtn
                        title="Start Selection"
                        icon="selectionstart"
                        disabled
                      />
                      <MediaBtn
                        title="End Selection"
                        icon="selectionend"
                        disabled
                      />
                    </React.Fragment>
                  )}
                  <Spacer />
                  <VerticalDivider />
                  <Spacer />
                  <Cutout shadow={false} style={{ flexGrow: 1 }}>
                    <span style={{ marginLeft: 2 }}>
                      {convertToTime(currentTime)}
                    </span>
                  </Cutout>
                </Toolbar>
              </WindowContent>
            </div>
          </DraggableWindow>
        </div>
      </ThemeProvider>
    );
  }
}

MediaPlayerView.propTypes = {
  getDisplayText: PropTypes.func.isRequired,
  showVideo: PropTypes.bool.isRequired,
  fullscreenEnabled: PropTypes.bool.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  fullscreen: PropTypes.bool.isRequired,
  requestFullscreen: PropTypes.func.isRequired,
  requestExitFullscreen: PropTypes.func.isRequired,
  playlist: PropTypes.arrayOf(PlayerPropTypes.track.isRequired).isRequired,
  activeTrackIndex: PropTypes.number.isRequired,
  paused: PropTypes.bool.isRequired,
  currentTime: PropTypes.number.isRequired,
  onSeekComplete: PropTypes.func.isRequired,
  onTogglePause: PropTypes.func.isRequired,
  onBackSkip: PropTypes.func.isRequired,
  onForwardSkip: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

module.exports = playerContextFilter(MediaPlayerView, [
  'fullscreen',
  'requestFullscreen',
  'requestExitFullscreen',
  'playlist',
  'activeTrackIndex',
  'paused',
  'currentTime',
  'onSeekComplete',
  'onTogglePause',
  'onBackSkip',
  'onForwardSkip',
]);
