import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { Window } from 'react95';

let DRAGGABLE_WINDOW_Z_INDEX = 10000;

const DraggableWindow = ({ children, style, ...props }) => {
  const [zIndex, setZIndex] = useState(DRAGGABLE_WINDOW_Z_INDEX);
  const incrementZIndex = () => {
    DRAGGABLE_WINDOW_Z_INDEX++;
    setZIndex(DRAGGABLE_WINDOW_Z_INDEX);
  };

  return (
    <Draggable
      handle=".handle"
      scale={1}
      onStart={incrementZIndex}
      onStop={incrementZIndex}
    >
      <Window {...props} onClick={incrementZIndex} style={{ ...style, zIndex }}>
        {children}
      </Window>
    </Draggable>
  );
};

export default DraggableWindow;
