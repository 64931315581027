const styled = require('styled-components').default;
const theSixtiesUSA = require('react95/dist/themes/theSixtiesUSA');

const VerticalDivider = styled.div`
  width: 0;
  border-right: 2px solid ${theSixtiesUSA.borderLightest};
  border-left: 2px solid ${theSixtiesUSA.borderDark};
  margin: -4px 0;
  align-self: stretch;
`;

module.exports = VerticalDivider;
