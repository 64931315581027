const TYPE_WEBM = 'video/webm; codecs=vp9,vorbis';
const TYPE_MP4 = 'video/mp4';

const playlist = [
  // {
  //   title: 'rtiff.avi',
  //   sources: [
  //     {
  //       src: `https://racertrash.sfo2.digitaloceanspaces.com/clips/RTIFF-trailer-final.mp4`,
  //       type: TYPE_MP4,
  //     },
  //   ],
  // },
  // {
  //   title: 'mm.xxml',
  //   sources: [
  //     {
  //       src: `https://racertrash.sfo2.digitaloceanspaces.com/clips/MMXXML_Trailer.mp4`,
  //       type: TYPE_MP4,
  //     },
  //   ],
  // },
  // {
  //   title: 'clueless trailer.mp4',
  //   sources: [
  //     {
  //       src:
  //         'https://racertrash.sfo2.digitaloceanspaces.com/clips/clueless-trailer_final-0212.mp4',
  //       type: TYPE_MP4,
  //     },
  //   ],
  // },
  {
    title: '2020 reel <3',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rt2k20_crunchd_3mbpsbaby.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'big year for pullman.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/bigyearforpullman.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/bigyearforpullman.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'gang gang gang gang.mp4',
    sources: [
      {
        src: `https://racertrash.sfo2.digitaloceanspaces.com/clips/gang%20gang%20gang%20gang.mp4`,
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'ycn.mp4',
    sources: [
      {
        src: `https://racertrash.sfo2.digitaloceanspaces.com/clips/ycn.mp4`,
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: "l'inconnue.mp4",
    sources: [
      {
        src: `https://racertrash.sfo2.digitaloceanspaces.com/clips/linconnue.mp4`,
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'really scare me gary.mp4',
    sources: [
      {
        src: `https://racertrash.sfo2.digitaloceanspaces.com/clips/really%20scare%20me%20gary.mp4`,
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'babypowder – miles davis.mp4',
    sources: [
      {
        src: `https://racertrash.sfo2.digitaloceanspaces.com/clips/babypowder.mp4`,
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'sleepwave (clip).mp4',
    sources: [
      {
        src: `https://racertrash.sfo2.digitaloceanspaces.com/clips/sleepwave%20(clip).mp4`,
        type: TYPE_MP4,
      },
    ],
  },
  // {
  //   title: 'VERTIHWG TRAILER.MP4',
  //   sources: [
  //     {
  //       src: `https://racertrash.sfo2.digitaloceanspaces.com/clips/VHWG-Trailer2.mp4`,
  //       type: TYPE_MP4,
  //     },
  //   ],
  // },
  {
    title: 'stares.mov',
    sources: [
      {
        src: `https://racertrash.sfo2.digitaloceanspaces.com/clips/IG_clueless_js.mp4`,
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'going down a shame spiral.avi',
    sources: [
      {
        src: `https://racertrash.sfo2.digitaloceanspaces.com/clips/aj_clueless_igclip2.mp4`,
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'the becoming.mp3',
    sources: [
      {
        src: `https://racertrash.sfo2.digitaloceanspaces.com/clips/aj_ygn_igclip2.mp4`,
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'what happened to the august 28th files?.webm',
    sources: [
      {
        src: `https://racertrash.sfo2.digitaloceanspaces.com/clips/aj_clueless_igclip3.mp4`,
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'right where it belongs.mp3',
    sources: [
      {
        src: `https://racertrash.sfo2.digitaloceanspaces.com/clips/aj_ygn_igclip1.mp4`,
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'near miss.m4v',
    sources: [
      {
        src: `https://racertrash.sfo2.digitaloceanspaces.com/clips/RM-Clueless_IG.mp4`,
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'the red ones.webm',
    sources: [
      {
        src: `https://racertrash.sfo2.digitaloceanspaces.com/clips/aj_clueless_igclip1.mp4`,
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'c u later alligator.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/abbadook%20_%20c%20u%20later%20alligator.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'dancing queen.mp3',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/abbadook%20_%20dancing%20queen.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'hi-eeeee.avi',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/abbadook%20_%20hi-eeeee.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'LMBIH.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/01_CTHD-Ted_V2-LMBIH2.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'the sword thief.avi',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/CTHDCRISTINAISABELRIVERA60SEC1.mp4',
        type: TYPE_MP4,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/CTHDCRISTINAISABELRIVERA60SEC1.webm',
        type: TYPE_WEBM,
      },
    ],
  },
  {
    title: 'clockcatcher.avi',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/ag_cthd_clockcatcher.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'SABOTAGE (live).mp3',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/AG_H4CK3RS_ig02_SABOTAGE.mp4',
        type: TYPE_MP4,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/AG_H4CK3RS_ig02_SABOTAGE.webm',
        type: TYPE_WEBM,
      },
    ],
  },
  {
    title: 'drinks before the show.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/cthd_aj_clip1.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'buzzballads.mov (Order Now!)',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/agcthdigclip2-buzzballads.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'kick over Wudan mountain.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/cthd_aj_clip3.mp4',
        type: TYPE_MP4,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/cthd_aj_clip3.webm',
        type: TYPE_WEBM,
      },
    ],
  },
  {
    title: 'babygang.mov',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/CTHD_AUSTIN_IG.mp4',
        type: TYPE_MP4,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/CTHD_AUSTIN_IG.webm',
        type: TYPE_WEBM,
      },
    ],
  },
  {
    title: `mens doesn't control me.mp4`,
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/CTHD_CL_instagram.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'the four animal tendencies.avi',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/CTHD_KTSKEL_SEG_v2_INSTA.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'little too high.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/CTHD_PaulBonanno_IG.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'koopa rap.mp3',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/Paul_KoopaRap_v3.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'i must dash.wmv',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/imustdash.mp4',
        type: TYPE_MP4,
      },
    ],
  },

  {
    title: 'have you no sense of.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/haveyounosenseof.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'fassbenders.mkv',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/Fassbender_CG_V3.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/Fassbender_CG_V3.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'missywave.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/missywave.md.v2.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/missywave.md.v2.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'queen of carpets.avi',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/queenofcarpets.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/queenofcarpets.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'carfox.3gp',
    sources: [
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/carfox.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/carfox.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'tight five.avi',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/paulIdracIG.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/paulIdracIG.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'racertrash: here we go.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/daniellealienIG.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/daniellealienIG.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'racer trash.avi',
    sources: [
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/alex_5.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/alex_5.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'dancin aliens.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/darbyalienIG.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/darbyalienIG.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'i got clientele.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/corygarciadrac_IG.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/corygarciadrac_IG.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'vampire hive.avi',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/ArielDracIG.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/ArielDracIG.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'shares guaranteed by law.mkv',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/chloealiengIG.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/chloealiengIG.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'side of the road.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/JasonDracIG.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/JasonDracIG.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'lil bit of this.webm',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/arielalienIG.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/arielalienIG.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'for the dead travel fast.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/clairevampIG.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/clairevampIG.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'christ compels you.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/RyanPerezDracIG.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/RyanPerezDracIG.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'she found peace.avi',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/CaseyDrac_IG.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/CaseyDrac_IG.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'ahh.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/darbydracIG.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/darbydracIG.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'the way u move.avi',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/austindracIG.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/austindracIG.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'unhuman fears.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/marisaalienIG.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/marisaalienIG.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'hatwave.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/benalieng2IG.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/benalieng2IG.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'where did the blood go.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/PaulB.DracIG.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/PaulB.DracIG.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'taking off at 11.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/tedalienIG.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/tedalienIG.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: "you're uninvited.mp4",
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/danielledrac_IG.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/danielledrac_IG.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: "the sun ain't gonna shine.mov",
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/elliealienIG.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/elliealienIG.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'hold me.mkv',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/jessalienIG.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/jessalienIG.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'you have my sympathies.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/benalienIG.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/benalienIG.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'spiders eat the flies.avi',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/harrydrac_IG.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/harrydrac_IG.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'under my garage.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/arielalienIG2.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/arielalienIG2.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'ダラス Darasu.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/aaronmolesAlienIG.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/aaronmolesAlienIG.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'juliet ugh (placeholder chunk v2).avi',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/julietugh.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/julietugh.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'do you bite your thumb at us sir.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-alex.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-alex.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'is that a man in there?.mov',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/is%20that%20a%20man%20in%20there.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/is%20that%20a%20man%20in%20there.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'lyndon.wav',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/LyndonWave.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/LyndonWave.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'listen.mp4',
    sources: [
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/alex_4.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/alex_4.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'add more fuel to your fire.AVI',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-ariel.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-ariel.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'scatland - a little shy.midi',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/Mario_Jess.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/Mario_Jess.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'to be in love (emotional).mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-pluimer.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-pluimer.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'this is my favorite part.mp4',
    sources: [
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/lasagna.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'gator.wav',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/alexg8rwav.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/alexg8rwav.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'julio "pasta" pissing diaries.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-harrison.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-harrison.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'balcony scene rpg.mov',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-josh.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-josh.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'guillotine.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/guillotine.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/guillotine.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'luigi.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/ted_luigi.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/ted_luigi.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'drum n bass ventura.AVI',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/Ariel_dnb.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/Ariel_dnb.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'hieroglyphics.avi',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/Harrison_mystery.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/Harrison_mystery.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: "i'm the baby (gotta love me).mp4",
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/yoshi_josh.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/yoshi_josh.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'come and get it (feat. SUUNS).avi',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/suuns_ariel.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/suuns_ariel.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'jewell.wav',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/paulwheatuswave.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/paulwheatuswave.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: "don't let the computer get on you.mp4",
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-casey.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-casey.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'why the big mystery?.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/ariel_mysterywav.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/ariel_mysterywav.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'cool beans.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/ariel_5.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/ariel_5.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'pfannkuchen.flv',
    sources: [
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/jake_2.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/jake_2.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'no faces.mkv',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/veggie_danielle.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/veggie_danielle.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'pool kissing (explicit).mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-jake.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-jake.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'boy tears.MP4',
    sources: [
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/ellie1.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/ellie1.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'grimy ghost.flv',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/Jared_1.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/Jared_1.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'borrowed likeness of shrunk death.avi',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-ellie2.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-ellie2.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'never too much.mkv',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/Ariel_2.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/Ariel_2.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'somethin special.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/Austin1.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/Austin1.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'darby wave.flv',
    sources: [
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/darby1.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/darby1.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'the sanctuary.flv',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-claire.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-claire.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'one ring.m4r',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/darby_3.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/darby_3.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'uhhh.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/Harrison_1.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/Harrison_1.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'ALIENS.M4V',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/danielle_1.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/danielle_1.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: "austin's vapor mario trailer.flv",
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/austin_SMBtrailer.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/austin_SMBtrailer.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'condition: fine.mkv',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-grimy.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-grimy.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'husband and husband.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/husband.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/husband.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'all computers.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/danny_3.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/danny_3.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'easyfun.aac',
    sources: [
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/ellie2.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/ellie2.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'ralph.mov',
    sources: [
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/BenP_1.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/BenP_1.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'ayotd.avi',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/darkwave_seg1_aj_elliecolor.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/darkwave_seg1_aj_elliecolor.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'racer wave superstar.mov',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/Ariel_4.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/Ariel_4.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'i drink to thee.mov',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-ellie.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-ellie.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'mouth sounds asmr (no talking).mov',
    sources: [
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/Josh_2.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/Josh_2.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'lil turd.avi',
    sources: [
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/sean_4.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/sean_4.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'shot of rye.MP4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/Joshsasson_3.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/Joshsasson_3.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'he loves her.MP4',
    sources: [
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/ellie3.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/ellie3.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'pool kissing (clean) (HORNY ALERT).webm',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-bobby.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-bobby.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: '4. GIMME ALL YOU GOT (feat kyle).MP3',
    sources: [
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/Kyle.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/Kyle.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'DO YOU KNOW ABOUT THIS.MOV',
    sources: [
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/Paul_2.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/Paul_2.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'pool guard smiles (boing).mkv',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-josh2.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-josh2.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'biff.wav',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/Danny_2.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/Danny_2.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'ayotd final 2 WIP v5.avi',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/darkwave_seg5_aj_elliecolor.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/darkwave_seg5_aj_elliecolor.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'coffee.flv',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/freddie_1.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/freddie_1.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'seek happy nights.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-jess.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-jess.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'heavy metal.MOV',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/Heavy%20Metal%20The%20Movie%20(1981)%20Trailer_1.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'sony playstation.webm',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/Austin_2.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/Austin_2.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'beautiful stranger.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/Jason_1.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/Jason_1.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'virus.MP4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/Jennifer_1.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/Jennifer_1.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: "ridin' on the highway.mov",
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-darby2.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-darby2.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'choices.flv',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/jordan_2.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/jordan_2.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'pay attention he loves her.avi',
    sources: [
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/Josh_1.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/Josh_1.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'aquarium love at first sight.avi',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-darby.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-darby.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'konami code cigar.mov',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/PaulI.1.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/PaulI.1.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'bagok.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/robby_1.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/robby_1.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'orange county dispatch.avi',
    sources: [
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/sean1.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/sean1.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'twitter timeline.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-chloe.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-chloe.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'no shirt no shoes no service.mp4',
    sources: [
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/Sean_2.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/Sean_2.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'let racing change you.mp4',
    sources: [
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/Sean_3.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/Sean_3.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'area 51 tour.MP4',
    sources: [
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/Ted_1.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/Ted_1.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'reduce reuse recycle.flv',
    sources: [
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/Zak_1.webm',
        type: TYPE_WEBM,
      },
      {
        src: 'https://racertrash.sfo2.digitaloceanspaces.com/clips/Zak_1.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'mellow tragedy.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-marisa.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-marisa.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'in the end (official music video).mp3',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-danielle.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/rj-danielle.mp4',
        type: TYPE_MP4,
      },
    ],
  },
  {
    title: 'woke cat trailer.mp4',
    sources: [
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/id4_wokecat_v1_elliecolor.webm',
        type: TYPE_WEBM,
      },
      {
        src:
          'https://racertrash.sfo2.digitaloceanspaces.com/clips/id4_wokecat_v1_elliecolor.mp4',
        type: TYPE_MP4,
      },
    ],
  },
].map((track) => ({ title: 'Media Player', ...track }));

export default playlist;
