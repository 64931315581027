import React, { useState, useCallback, useEffect } from 'react';
import loadable from '@loadable/component';
import styled from 'styled-components';

import { MediaBtn, MediaIcon } from './media/index.js';
import DraggableWindow from './DraggableWindow.js';

import {
  WindowHeader,
  WindowContent,
  Toolbar,
  Button,
  Cutout,
  Divider,
  List,
  ListItem,
} from 'react95';

import VerticalDivider from './media/components/VerticalDivider.js';

import explorerBrowserIcon from '../images/explorer/browser.png';
import warningIcon from '../images/warning.png';
import aboutSegsplorer from '../images/browser/about.png';
import twitterIcon from '../images/socials/twitter.png';
import instagramIcon from '../images/socials/instagram.png';
import twitchIcon from '../images/socials/twitch.png';
import spotifyIcon from '../images/socials/spotify.png';
import bandcampIcon from '../images/socials/bandcamp.png';
import homepageImage from '../images/browser/homepage.gif';
import directoryOpen from '../images/browser/directoryOpen.png';
import directoryClosed from '../images/browser/directoryClosed.png';

const Clippy = loadable(() => import('../components/racerx/Clippy.js'));

const TinyListItem = styled(ListItem)`
  font-size: 13px;
`;

const theSixtiesUSA = require('react95/dist/themes/theSixtiesUSA');

const FlatButton = styled(Button)`
  &::before {
    border-width: 0;
    box-shadow: none;
  }

  &:active::before {
    border-width: 0;
  }

  &:focus::after,
  &:active::after {
    outline: 0;
  }

  &[disabled]:hover::before {
    box-shadow: none;
  }
`;

const CenteredDraggableWindow = styled(DraggableWindow)`
  width: 400px;
  max-width: 98vw;
  position: absolute !important;
  top: calc(50vh - 128px);
  left: calc(50vw - 200px);

  @media only screen and (max-width: 400px) {
    left: 1vw;
  }
`;

const StyledBrowserButton = styled(FlatButton)`
  flex-direction: column;
  min-width: 50px;
  height: 40px;
  font-size: 13px;
  color: #010001;

  > :first-child {
    display: flex;
    align-items: center;
  }

  &[disabled] {
    text-shadow: 1px 1px 0px #df9be7;
    color: #6c1f71;
  }

  &:hover::before {
    box-shadow: inset 1px 1px 0px 1px #df9be7, inset -1px -1px 0 1px #d982de;
  }

  &[data-active='true'] {
    background: #df9be7;
    &::before {
      box-shadow: inset 1px 1px 0px 1px #6c1f71, inset -1px -1px 0 1px #d982de !important;
    }
  }
`;
const BrowserButton = ({ text, sprite, hasMenu = false, ...rest }) => {
  return (
    <StyledBrowserButton {...rest}>
      <div>
        <div className={`browser browser-${sprite}`} />
        {hasMenu && (
          <div className="browser browser-down" style={{ marginLeft: 5 }} />
        )}
      </div>
      <div>{text}</div>
    </StyledBrowserButton>
  );
};

const PrinterWarning = ({ onClose }) => {
  return (
    <CenteredDraggableWindow>
      <WindowHeader className="handle" style={{ lineHeight: '30px' }}>
        <Toolbar
          style={{
            padding: 0,
          }}
        >
          <span style={{ flexGrow: 1 }}>Printing Error</span>
          <MediaBtn
            title="Close"
            icon="x"
            style={{ width: '24px', height: '24px' }}
            onClick={onClose}
            onTouchEnd={onClose}
          />
        </Toolbar>
      </WindowHeader>
      <WindowContent>
        <Cutout
          style={{
            background: 'white',
          }}
        >
          <div
            style={{
              display: 'flex',
              padding: '8px',
              overflow: 'auto',
              fontSize: 15,
            }}
          >
            <img
              src={warningIcon}
              width={48}
              height={48}
              alt="Warning"
              style={{ flexShrink: 0 }}
            />
            <div style={{ marginLeft: '20px' }}>
              Before you can print, you need to install a printer. To do this,
              click Start, point to Settings, click Printers, and then
              double-click Add Printer.
              <br />
              <Button
                onClick={onClose}
                style={{ padding: '0 40px', marginTop: 8 }}
              >
                OK
              </Button>
            </div>
          </div>
        </Cutout>
      </WindowContent>
      <Clippy message="It looks like you're trying to print.... ꜱᴏʀʀʏ, ᴘʀɪɴᴛ ɪꜱ ᴅᴇᴀᴅ." />
    </CenteredDraggableWindow>
  );
};

const HelpMenuList = styled(List)`
  position: absolute;
  left: 0;
  top: 100%;
  min-width: 160;
  z-index: 100;

  @media only screen and (max-width: 480px) {
    left: auto;
    right: 0;
  }
`;

const AboutSegsplorer = ({ onClose }) => {
  return (
    <CenteredDraggableWindow style={{ width: 432 }}>
      <WindowHeader className="handle" style={{ lineHeight: '30px' }}>
        <Toolbar
          style={{
            padding: 0,
          }}
        >
          <img
            src={explorerBrowserIcon}
            alt=""
            style={{
              width: '16px',
              height: '16px',
              marginTop: -2,
              marginRight: 3,
            }}
          />
          <span style={{ flexGrow: 1 }}>About Internet Segsplorer</span>
          <MediaBtn
            title="Close"
            icon="x"
            style={{ width: '24px', height: '24px' }}
            onClick={onClose}
            onTouchEnd={onClose}
          />
        </Toolbar>
      </WindowHeader>
      <div style={{ background: 'transparent' }}>
        <div>
          <img
            src={aboutSegsplorer}
            alt=""
            width="420"
            height="158"
            style={{ width: '100%', height: 'auto' }}
          />
        </div>
        <div style={{ fontSize: 13 }} className="segsplorer-content">
          <div>
            Version: 69.00.420.2021
            <br />
            Cipher Stength: 40-bit (<u>Update Information</u>)<br />
            Product ID:
          </div>
          <Cutout
            style={{
              background: 'white',
              padding: 5,
              fontSize: 13,
              overflowY: 'scroll',
              margin: '15px 0',
            }}
          >
            Based on NCSA Mosaic. NCSA Mosaic(TM); was developed at the National
            Center for Supercomputing Applications at the University of Illinois
            at Urbana-Champaign.
          </Cutout>
          <u>Copyright &copy;1995-1999 Racing Junk Ltd.</u>
        </div>
      </div>
    </CenteredDraggableWindow>
  );
};

const FavoritesButton = styled.a`
  display: inline-flex;
  align-items: center;
  border: 0;
  background: none;
  text-align: left;
  padding: 0;
  max-width: 100%;

  &[data-href] {
    cursor: pointer;
  }

  &:hover {
    color: inherit;
  }

  &[href]:hover,
  &[data-href]:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: 1px dotted black;
  }

  &::-moz-focus-inner {
    border: 0;
  }

  img {
    width: 16px;
    height: 16px;
    margin: -2px 5px 0 0;
  }
  span {
    font-size: 14px;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
    flex: 1;
  }
`;
const FavoritesItem = ({ children, icon = explorerBrowserIcon, ...rest }) => {
  return (
    <div>
      <FavoritesButton {...rest}>
        <img src={icon} alt="" />
        <span>{children}</span>
      </FavoritesButton>
    </div>
  );
};

const BrowserToolbar = styled(Toolbar)`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  position: relative;
  height: 40px;

  @media only screen and (max-width: 559px) {
    overflow: hidden;

    .browser-window-phone-hidden-mail-print {
      display: none !important;
    }
  }
`;

const MenuOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
`;

const BrowserWindow = ({ onClose }) => {
  const [state, setState] = useState({
    printerWarningOpen: false,
    aboutSegsplorerOpen: false,
    favoritesOpen: false,
    historyOpen: false,
    homepageOpen: false,
    mailMenuOpen: false,
    helpMenuOpen: false,
    favoritesMerchExpanded: false,
    favoritesPressExpanded: false,
  });

  const {
    printerWarningOpen,
    aboutSegsplorerOpen,
    favoritesOpen,
    historyOpen,
    homepageOpen,
    mailMenuOpen,
    helpMenuOpen,
    favoritesMerchExpanded,
    favoritesPressExpanded,
  } = state;

  const setPrinterWarningOpen = (printerWarningOpen) => {
    setState({ ...state, printerWarningOpen });
  };
  const setAboutSegsplorerOpen = (aboutSegsplorerOpen) => {
    setState({ ...state, helpMenuOpen: false, aboutSegsplorerOpen });
  };
  const setFavoritesOpen = (favoritesOpen) => {
    setState({ ...state, historyOpen: false, favoritesOpen });
  };
  const setHistoryOpen = (historyOpen) => {
    setState({ ...state, favoritesOpen: false, historyOpen });
  };
  const setHomepageOpen = (homepageOpen) => {
    setState({ ...state, homepageOpen });
  };
  const setMailMenuOpen = (mailMenuOpen) => {
    setState({ ...state, mailMenuOpen });
  };
  const setHelpMenuOpen = (helpMenuOpen) => {
    setState({ ...state, helpMenuOpen });
  };
  const setFavoritesMerchExpanded = (favoritesMerchExpanded) => {
    setState({ ...state, favoritesMerchExpanded });
  };
  const setFavoritesPressExpanded = (favoritesPressExpanded) => {
    setState({ ...state, favoritesPressExpanded });
  };

  const reload = () => {
    try {
      document.querySelector('#moviesIframe').contentWindow.location.reload();
    } catch (e) {}
  };

  const Homepage = () => {
    return (
      <div
        style={{ maxHeight: 384, overflowY: 'scroll' }}
        role="button"
        tabIndex="0"
        title="Continue"
        onClick={() => setHomepageOpen(false)}
        onKeyPress={() => setHomepageOpen(false)}
      >
        <img
          src={homepageImage}
          alt=""
          width="800"
          height="798"
          style={{ display: 'block', width: '100%', height: 'auto' }}
        />
      </div>
    );
  };

  const onMessageReceivedFromIframe = useCallback((event) => {
    if (event.data === 'geocities:click') {
      try {
        document
          .querySelector('#moviesIframe')
          .closest('.react-draggable')
          .click();
      } catch (e) {}
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', onMessageReceivedFromIframe);
    return () =>
      window.removeEventListener('message', onMessageReceivedFromIframe);
  }, [onMessageReceivedFromIframe]);

  return (
    <>
      <CenteredDraggableWindow
        style={{
          width: 640,
          top: '66px',
        }}
      >
        {mailMenuOpen && <MenuOverlay onClick={() => setMailMenuOpen(false)} />}
        {helpMenuOpen && <MenuOverlay onClick={() => setHelpMenuOpen(false)} />}

        <WindowHeader className="handle" style={{ lineHeight: '30px' }}>
          <Toolbar
            style={{
              padding: 0,
            }}
          >
            <img
              src={explorerBrowserIcon}
              alt=""
              style={{
                width: '16px',
                height: '16px',
                marginTop: -2,
                marginRight: 3,
              }}
            />
            <span style={{ flexGrow: 1 }}>Microsoft Internet Explorer</span>
            <MediaBtn
              title="Close"
              icon="x"
              style={{ width: '24px', height: '24px' }}
              onClick={onClose}
              onTouchEnd={onClose}
            />
          </Toolbar>
        </WindowHeader>
        <Toolbar style={{ padding: 0, position: 'relative' }}>
          {['File', 'Edit', 'View', 'Favorites', 'Tools'].map((menuHeader) => (
            <FlatButton
              key={menuHeader}
              style={{ fontSize: 13, height: '1.6em' }}
              size="sm"
              flat
              disabled
            >
              <u>{menuHeader[0]}</u>
              {menuHeader.slice(1)}
            </FlatButton>
          ))}

          <div style={{ position: 'relative', zIndex: mailMenuOpen ? 1 : 6 }}>
            <FlatButton
              style={{ fontSize: 13, height: '1.6em' }}
              size="sm"
              flat
              onClick={() => setHelpMenuOpen(!helpMenuOpen)}
            >
              <u>H</u>elp
            </FlatButton>
            {helpMenuOpen && (
              <HelpMenuList onClick={() => setHelpMenuOpen(false)}>
                <TinyListItem size="sm" disabled>
                  <span>
                    Tip of the <u>D</u>ay
                  </span>
                </TinyListItem>
                <TinyListItem size="sm" disabled>
                  <span>
                    <u>T</u>our
                  </span>
                </TinyListItem>
                <TinyListItem size="sm" disabled>
                  <span>
                    Online <u>S</u>upport
                  </span>
                </TinyListItem>
                <ListItem
                  size="sm"
                  as="a"
                  href={`mailto:webmaster@racertrash.com?subject=${encodeURIComponent(
                    'Website Feedback'
                  )}`}
                  target="_blank"
                  style={{ fontSize: 13 }}
                >
                  <span>
                    Send Feedbac<u>k</u>
                  </span>
                </ListItem>
                <Divider />
                <TinyListItem
                  size="sm"
                  onClick={() => setTimeout(() => setAboutSegsplorerOpen(true))}
                >
                  <span>
                    <u>A</u>bout Internet Segsplorer
                  </span>
                </TinyListItem>
              </HelpMenuList>
            )}
          </div>
        </Toolbar>
        <Divider />
        <BrowserToolbar>
          <BrowserButton text="Back" sprite="back" disabled />
          <BrowserButton text="Forward" sprite="forward" disabled />

          <div style={{ display: 'flex' }}>
            <VerticalDivider style={{ margin: 0 }} />
            <BrowserButton text="Stop" sprite="stop" />
            <BrowserButton text="Refresh" sprite="refresh" onClick={reload} />
            <BrowserButton
              text="Home"
              sprite="home"
              onClick={() => setHomepageOpen(true)}
            />
          </div>

          <div style={{ display: 'flex' }}>
            <VerticalDivider style={{ margin: 0 }} />
            <BrowserButton
              text="Favorites"
              sprite="favorites"
              onClick={() => setFavoritesOpen(!favoritesOpen)}
              data-active={favoritesOpen}
            />
          </div>
          {/* <BrowserButton text="Search" sprite="search" /> */}
          <BrowserButton
            text="History"
            sprite="history"
            onClick={() => setHistoryOpen(!historyOpen)}
            data-active={historyOpen}
          />
          <div
            className="browser-window-phone-hidden-mail-print"
            style={{ display: 'flex' }}
          >
            <VerticalDivider style={{ margin: 0 }} />

            <div style={{ position: 'relative', zIndex: helpMenuOpen ? 1 : 6 }}>
              <BrowserButton
                text="Mail"
                sprite="mail"
                data-active={mailMenuOpen}
                onClick={() => setMailMenuOpen(!mailMenuOpen)}
                hasMenu={true}
              />
              {mailMenuOpen && (
                <List
                  style={{
                    position: 'absolute',
                    left: '0',
                    top: '100%',
                    minWidth: 160,
                    zIndex: 100,
                  }}
                  onClick={() => setMailMenuOpen(false)}
                >
                  <TinyListItem size="sm" disabled>
                    <span>
                      Read <u>M</u>ail
                    </span>
                  </TinyListItem>
                  <ListItem
                    size="sm"
                    as="a"
                    href={`mailto:?subject=${encodeURIComponent(
                      'FWD: FWD: FWD: Check out Racer Trash!'
                    )}&body=${encodeURIComponent('http://racertrash.com/')}`}
                    target="_blank"
                    style={{ fontSize: 13 }}
                  >
                    <span>
                      Ne<u>w</u> Message...
                    </span>
                  </ListItem>
                  <TinyListItem size="sm" disabled>
                    <span>
                      Send a <u>L</u>ink
                    </span>
                  </TinyListItem>
                  <TinyListItem size="sm" disabled>
                    <span>
                      Send <u>P</u>age
                    </span>
                  </TinyListItem>
                  <Divider />
                  <TinyListItem size="sm" disabled>
                    <span>
                      Read <u>N</u>ews
                    </span>
                  </TinyListItem>
                </List>
              )}
            </div>
            <BrowserButton
              text="Print"
              sprite="print"
              onClick={() => setTimeout(() => setPrinterWarningOpen(true), 222)}
            />
          </div>
        </BrowserToolbar>
        <Divider />
        <Toolbar>
          <span
            className="browser-window-phone-hidden-1"
            style={{ marginRight: 4, fontSize: 13 }}
          >
            A<u>d</u>dress
          </span>
          <Cutout style={{ background: 'white', flex: '1' }}>
            <div
              style={{ display: 'flex', alignItems: 'center', cursor: 'text' }}
            >
              <img
                src={explorerBrowserIcon}
                alt=""
                style={{
                  width: '16px',
                  height: '16px',
                  margin: '-2px 5px 0 2px',
                }}
              />
              <span
                style={{ fontSize: 14, lineHeight: '24px', marginRight: 5 }}
              >
                {homepageOpen ? (
                  'http://www.racertrash.com/index.htm'
                ) : (
                  <>
                    http://www.geocities.com/RacerTrash/
                    <span className="browser-window-phone-hidden-1">
                      movies.htm
                    </span>
                  </>
                )}
              </span>
            </div>
          </Cutout>
          <StyledBrowserButton
            className="browser-window-phone-hidden-2"
            style={{
              height: 32,
              flexDirection: 'row',
              flexWrap: 'nowrap',
              alignItems: 'center',
              padding: 0,
            }}
            onClick={reload}
          >
            <div className="browser browser-go" />
            <span style={{ marginLeft: 4 }}>Go</span>
          </StyledBrowserButton>
        </Toolbar>
        <div style={{ display: 'flex' }}>
          {favoritesOpen && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: 200,
                  height: 384,
                }}
              >
                <Divider />
                <div
                  style={{
                    fontSize: 14,
                    padding: '2px 2px 2px 6px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  Favorites
                  <FlatButton
                    style={{ height: 22 }}
                    onClick={() => setFavoritesOpen(false)}
                  >
                    <MediaIcon name="x" />
                  </FlatButton>
                </div>

                <div
                  style={{
                    background: 'white',
                    padding: 8,
                    height: '100%',
                    borderTop: `1px solid ${theSixtiesUSA.borderDark}`,
                    overflowY: 'scroll',
                  }}
                >
                  <FavoritesItem
                    as="button"
                    data-href
                    onClick={() => {
                      homepageOpen ? setHomepageOpen(false) : reload();
                    }}
                  >
                    Racer Trash Movies
                  </FavoritesItem>

                  <FavoritesItem
                    href="https://twitter.com/racertrash"
                    target="_blank"
                    rel="noopener noreferrer"
                    icon={twitterIcon}
                  >
                    Twitter
                  </FavoritesItem>
                  <FavoritesItem
                    href="https://instagram.com/racertrash"
                    target="_blank"
                    rel="noopener noreferrer"
                    icon={instagramIcon}
                  >
                    Instagram
                  </FavoritesItem>
                  <FavoritesItem
                    href="https://twitch.tv/racerxtrash"
                    target="_blank"
                    rel="noopener noreferrer"
                    icon={twitchIcon}
                  >
                    Twitch.tv
                  </FavoritesItem>
                  <FavoritesItem
                    href="https://open.spotify.com/playlist/3eNoN8LQOKWw1B6cMGmBhO?si=YjTOgQ-DRbeFB1h701aSrg"
                    target="_blank"
                    rel="noopener noreferrer"
                    icon={spotifyIcon}
                  >
                    Spotify
                  </FavoritesItem>
                  <FavoritesItem
                    href="https://racertrash.bandcamp.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    icon={bandcampIcon}
                  >
                    Bandcamp
                  </FavoritesItem>

                  <FavoritesItem
                    as="button"
                    onClick={() =>
                      setFavoritesMerchExpanded(!favoritesMerchExpanded)
                    }
                    icon={
                      favoritesMerchExpanded ? directoryOpen : directoryClosed
                    }
                    style={{ width: '100%' }}
                  >
                    Schwag
                  </FavoritesItem>
                  {favoritesMerchExpanded && (
                    <div style={{ marginLeft: '16px' }}>
                      {[
                        [
                          'Janus Trash',
                          'https://tracerrash.threadless.com/designs/j-trash',
                        ],
                        [
                          `You've Got Nails (2021)`,
                          'https://www.teepublic.com/t-shirt/19788120-youve-got-nails-2021?store_id=441192',
                        ],
                        [
                          'LMBIH',
                          'https://tracerrash.threadless.com/designs/lmbih',
                        ],
                        [
                          'draculol',
                          'https://tracerrash.threadless.com/designs/draculol',
                        ],
                        [
                          'racer trash by ted',
                          'https://tracerrash.threadless.com/designs/racer-trash-by-ted',
                        ],
                        [
                          'frito',
                          'https://tracerrash.threadless.com/designs/frito',
                        ],
                        [
                          'racer trash by ben',
                          'https://tracerrash.threadless.com/designs/racertrash-by-ben',
                        ],
                        [
                          'racer trash logo',
                          'https://tracerrash.threadless.com/designs/rtshirt-smaller',
                        ],
                        [
                          'Racer Lucas Trash',
                          'https://www.teepublic.com/t-shirt/17101364-racer-lucas-trash-films?store_id=551219',
                        ],
                        [
                          'big year!',
                          'https://www.teepublic.com/t-shirt/17037398-big-year?store_id=551219',
                        ],
                        [
                          'racertrash pictures by claire',
                          'https://www.teepublic.com/t-shirt/15593281-racertrash-pictures-by-claire?store_id=559577',
                        ],
                        [
                          'from racer trash everywhere by claire',
                          'https://www.teepublic.com/t-shirt/15592506-from-racer-trash-everywhere-by-claire?store_id=559577',
                        ],
                        [
                          'from racer trash by robby',
                          'https://www.teepublic.com/t-shirt/15590629-from-racer-trash-by-robby?store_id=559577',
                        ],
                        [
                          'juliet, ugh!',
                          'https://www.zazzle.com/pd/spp/pt-zazzle_shirt?dz=9a6f0c29-47a5-433e-94b9-efa602d42a92',
                        ],
                        [
                          'whole move? hat',
                          'https://www.zazzle.com/whole_movie_hat-233674988034453699',
                        ],
                        [
                          'whole move? shirt',
                          'https://www.zazzle.com/whole_movie_shirt-235264940243764552',
                        ],
                        [
                          'plague racer trash',
                          'https://www.zazzle.com/pd/spp/pt-zazzle_shirt?dz=5238f205-3a4d-46b1-b208-613236676cd4',
                        ],
                        [
                          'plague in plague',
                          'https://www.zazzle.com/pd/spp/pt-zazzle_shirt?dz=dfe4e1e1-25b8-4c71-9b25-f9db426e045b',
                        ],
                        [
                          'plague plague plague',
                          'https://www.zazzle.com/pd/spp/pt-zazzle_shirt?dz=2ad9399b-a4ac-4328-82fb-a18360a125b8',
                        ],
                        [
                          'your life is...',
                          'https://www.zazzle.com/pd/spp/pt-zazzle_shirt?dz=2b0f687e-6646-4dbe-b7c8-8a1193202be9',
                        ],
                        [
                          'racer trash v2',
                          'https://www.teepublic.com/t-shirt/11340742-racer-trash-v2?store_id=158520',
                        ],
                        [
                          'Francis Ford Geddaboudit',
                          'https://www.teepublic.com/t-shirt/15952315-francis-ford-geddaboudit',
                        ],
                      ].map(([title, link]) => (
                        <FavoritesItem
                          href={link}
                          target="_blank"
                          rel="noopener noreferrer"
                          title={title}
                          key={title}
                        >
                          {title}
                        </FavoritesItem>
                      ))}
                    </div>
                  )}

                  <FavoritesItem
                    as="button"
                    onClick={() =>
                      setFavoritesPressExpanded(!favoritesPressExpanded)
                    }
                    icon={
                      favoritesPressExpanded ? directoryOpen : directoryClosed
                    }
                    style={{ width: '100%' }}
                  >
                    Press
                  </FavoritesItem>
                  {favoritesPressExpanded && (
                    <div style={{ marginLeft: '16px' }}>
                      {[
                        [
                          'Enjoy This BABADOOK-Themed Parody of ABBA’s “Waterloo” (Nerdist)',
                          'https://nerdist.com/article/abba-waterloo-parody-babadook/',
                        ],
                        [
                          'The Happy List: 28 pop culture pleasures worth smiling about (Entertainment Weekly)',
                          'https://ew.com/celebrity/happy-list-2021/',
                        ],
                        [
                          'The Horror that Helped: The Content that Made 2020 a Bit Brighter (Shudder Blog)',
                          'https://www.shudder.com/blog/the-horror-that-helped-the-content-that-made-2020-a-bit-brighter',
                        ],
                        [
                          'Halloween movie viewing parties go virtual (CBC)',
                          'https://www.cbc.ca/player/play/1813936707867',
                        ],
                        [
                          `I Like To Movie Movie #173 - Talkin' Trash with R A C E R T R A S H`,
                          'https://ilike2movie.libsyn.com/173-talkin-trash-with-r-a-c-e-r-t-r-a-s-h',
                        ],
                      ].map(([title, link = '']) => (
                        <FavoritesItem
                          href={link}
                          target="_blank"
                          rel="noopener noreferrer"
                          title={title}
                          key={title}
                        >
                          {title}
                        </FavoritesItem>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <VerticalDivider style={{ margin: '0 3px' }} />
            </>
          )}
          {historyOpen && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: 200,
                  height: 384,
                }}
              >
                <Divider />
                <div
                  style={{
                    fontSize: 14,
                    padding: '2px 2px 2px 6px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  History
                  <FlatButton
                    style={{ height: 22 }}
                    onClick={() => setHistoryOpen(false)}
                  >
                    <MediaIcon name="x" />
                  </FlatButton>
                </div>

                <div
                  style={{
                    background: 'white',
                    padding: 8,
                    height: '100%',
                    borderTop: `1px solid ${theSixtiesUSA.borderDark}`,
                    overflowY: 'scroll',
                  }}
                >
                  {[
                    [
                      'who is channing taters - Google Search',
                      'https://www.google.com/search?q=who+is+channing+taters',
                    ],
                    [
                      '(5) The Corrs - What Can I Do [Official Video] - YouTube',
                      'https://www.youtube.com/watch?v=GP4Cq3VTagU',
                    ],
                    [
                      'what can i do? - Google Search',
                      'https://www.google.com/search?q=what+can+i+do%3F',
                    ],
                    [
                      'Your Shopping Cart – BaronHats',
                      'https://baronhats.com/cart',
                    ],
                    [
                      'The RJ (The Thing 1982) – BaronHats',
                      'https://baronhats.com/products/the-rj-the-thing',
                    ],
                    [
                      'the hat from the thing - Google Search',
                      'https://www.google.com/search?q=the+hat+from+the+thing&tbm=isch',
                    ],
                    [
                      'Editions of Dracula by Bram Stoker - Goodreads',
                      'https://www.goodreads.com/work/editions/3165724-dracula',
                    ],
                    [
                      'Dracula by Bram Stoker - Goodreads',
                      'https://www.goodreads.com/book/show/17245.Dracula',
                    ],
                    [
                      'Count Dracula in popular culture - Wikipedia',
                      'https://en.wikipedia.org/wiki/Count_Dracula_in_popular_culture',
                    ],
                    [
                      'Dracula - Wikipedia',
                      'https://en.wikipedia.org/wiki/Dracula',
                    ],
                    [
                      'romeo and juliet baz luhrmann full movie (uncut including kissing scene horny style) - Google Search',
                      'https://www.google.com/search?q=romeo+and+juliet+baz+luhrmann+full+movie+(uncut+including+kissing+scene+horny+style)',
                    ],
                    [
                      'kmart 1980s',
                      'https://www.google.com/search?q=kmart+1980s&tbm=isch',
                    ],
                    [
                      'CRYSTAL PEPSI Employee Training Video (1992) - YouTube',
                      'https://www.youtube.com/watch?v=JJYsS82khTc',
                    ],
                    [
                      'crystal pepsi - Google Search',
                      'https://www.google.com/search?q=crystal+pepsi',
                    ],
                    [
                      `japan's rise to power as a capitalist superpower - Google Search`,
                      'https://www.google.com/search?q=japan%27s+rise+to+power+as+a+capitalist+superpower',
                    ],
                    [
                      'NIGHT CITY CityPop シティポップ 80s Japanese Mix - YouTube',
                      'https://www.youtube.com/watch?v=SNcyCNes3JY',
                    ],
                    [
                      'japanese citypop - Google Search',
                      'https://www.google.com/search?q=japanese+citypop',
                    ],
                    [
                      'Vaporwave - Wikipedia',
                      'https://en.wikipedia.org/wiki/Vaporwave',
                    ],
                    [
                      'vaporwave - Google Search',
                      'https://www.google.com/search?q=vaporwave',
                    ],
                    [
                      'YouTube Poop - Wikipedia',
                      'https://en.wikipedia.org/wiki/YouTube_Poop',
                    ],
                    [
                      'youtube poop - Google Search',
                      'https://www.google.com/search?q=youtube+poop',
                    ],
                    [
                      'YTP: Mario Writes a Book Report on The Odyssey - YouTube',
                      'https://www.youtube.com/watch?v=akKK7Wmq7JA',
                    ],
                    [
                      'Super Mario Brothers Super Show Intro - YouTube',
                      'https://www.youtube.com/watch?v=BkWYP95WbbY',
                    ],
                    [
                      'super mario brother super show - YouTube',
                      'https://www.youtube.com/results?search_query=super+mario+brother+super+show',
                    ],
                    [
                      'The Super Mario Bros. Super Show! - Wikipedia',
                      'https://en.wikipedia.org/wiki/The_Super_Mario_Bros._Super_Show!',
                    ],
                    [
                      'Shigeru Miyamoto - Wikipedia',
                      'https://en.wikipedia.org/wiki/Shigeru_Miyamoto',
                    ],
                    [
                      'Super Mario Bros. - Wikipedia',
                      'https://en.wikipedia.org/wiki/Super_Mario_Bros.',
                    ],
                    [
                      'Super Mario Bros. (1985) Full Walkthrough NES Gameplay [Nostalgia] (NO WARP) - YouTube',
                      'https://www.youtube.com/watch?v=rLl9XBg7wSs',
                    ],
                    [
                      'super mario brothers - Google Search',
                      'https://www.google.com/search?q=super+mario+brothers&oq=super+mario+brothers',
                    ],
                    [
                      'Who can fly? - Yahoo Search Results',
                      'https://search.yahoo.com/search?p=Who+can+fly%3F',
                    ],
                    [
                      'what is biffwave? - Yahoo Search Results',
                      'https://search.yahoo.com/search?p=what+is+biffwave%3F',
                    ],
                  ].map(([title, link]) => (
                    <FavoritesItem
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      title={title}
                      key={title}
                    >
                      {title}
                    </FavoritesItem>
                  ))}
                </div>
              </div>
              <VerticalDivider style={{ margin: '0 3px' }} />
            </>
          )}

          <Cutout style={{ background: 'white', flex: 1 }}>
            {homepageOpen ? (
              <Homepage />
            ) : (
              <iframe
                src="/geocities"
                title="Our Movies"
                id="moviesIframe"
                width="100%"
                height="384"
                style={{ display: 'block' }}
              />
            )}
          </Cutout>
        </div>
      </CenteredDraggableWindow>
      {printerWarningOpen && (
        <PrinterWarning onClose={() => setPrinterWarningOpen(false)} />
      )}
      {aboutSegsplorerOpen && (
        <AboutSegsplorer onClose={() => setAboutSegsplorerOpen(false)} />
      )}
    </>
  );
};

export default BrowserWindow;
