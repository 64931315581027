import React from 'react';
import loadable from '@loadable/component';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import { styleReset } from 'react95';
import theSixtiesUSA from 'react95/dist/themes/theSixtiesUSA';

import Seo from '../components/seo';
import DesktopEnvironment from '../components/racerx/DesktopEnvironment';
import TemporarySnowfall from '../components/TemporarySnowfall';
import ScrollbarCursorStyles from '../components/ScrollbarCursorStyles';

import NEXTSHOW from '../../NEXTSHOW.js';

const CLIPPY_HIDE_AFTER_DATE = new Date(Date.parse(NEXTSHOW));
CLIPPY_HIDE_AFTER_DATE.setHours(CLIPPY_HIDE_AFTER_DATE.getHours() + 3);

const Clippy =
  CLIPPY_HIDE_AFTER_DATE > new Date()
    ? loadable(() => import('../components/racerx/Clippy.js'))
    : () => null;

const { when, timezone } = ((DATE) => ({
  timezone: ((d) => {
    try {
      const parts = d.toString().match(/\((.+)\)/);
      return parts[1];
    } catch (e) {
      return null;
    }
  })(DATE),
  when: new Intl.DateTimeFormat(undefined, {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }).format(DATE),
}))(new Date(Date.parse(NEXTSHOW)));

const clippyMessage = `The next show is ${when} ${timezone}`.trim();

const GlobalStyles = createGlobalStyle`
  ${styleReset}

  #work {
    -ms-interpolation-mode: nearest-neighbor;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-pixelated;
    image-rendering: pixelated;
    &::after {
      content: '';
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 9999999;
      opacity: 0.7;
      width: 100%;
      height: 100%;
      overflow: hidden;
      pointer-events: none;
      background-image: radial-gradient(
          ellipse at center,
          transparent 0,
          transparent 60%,
          rgba(0, 0, 0, ${(15 * 0.65) / 100}) 100%
        ),
        repeating-linear-gradient(
          0deg,
          transparent,
          transparent 1px,
          rgba(0, 0, 0, ${(35 * 0.65) / 100}) 3px
        );
      background-size: 100% 100%, 100% 6px;
    }
  }

  .clippy.clippy,
  .clippy-balloon.clippy-balloon {
    z-index: 9999999;
  }

  .clippy.clippy {
    top: calc(100vh - 47px - 30px - 93px - 50px);
    bottom: calc(47px + 30px);
    left: calc(100vw - 124px - 50px);
    filter: hue-rotate(82deg) saturate(3);
  }
`;

const WorkPage = (props) => (
  <div id="work">
    <Seo
      title="OUR WORK"
      description="Explore the portfolio of radical editing collective RACER TRASH"
    />
    <ScrollbarCursorStyles />
    <GlobalStyles />
    <ThemeProvider theme={theSixtiesUSA}>
      <DesktopEnvironment {...props} />
      <Clippy message={clippyMessage} />
    </ThemeProvider>
    <TemporarySnowfall />
  </div>
);

export default WorkPage;
